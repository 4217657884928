import { IDfaFront } from '@dltru/dfa-models'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './CouponPaymentModal.m.less'

interface IHeaderProps {
    onClose: () => void
    dfa?: IDfaFront
}

export const Header: FC<IHeaderProps> = ({ dfa, onClose }) => {
    const navigate = useNavigate()

    const clickEmissionHandler = useCallback((e: React.SyntheticEvent): void => {
        e.preventDefault()
        if (dfa) {
            navigate(`/offers/${dfa.id}`)
            onClose()
        }
    }, [])

    const clickInvestorHandler = useCallback((e: React.SyntheticEvent): void => {
        e.preventDefault()
        if (dfa) {
            navigate(`/clients/${dfa.emitter_id}`)
            onClose()
        }
    }, [])

    return (
        <div>
            <a
                href="/"
                className="card-modal-header__link-to--emission"
                onClick={clickEmissionHandler}
            >
                {dfa?.ticker_symbol}
            </a>
            <p className="card-modal-header__title">
                {dfa?.emitter_full_name ?? dfa?.emitter_info?.short_name}
            </p>
            <a
                href="/"
                className="card-modal-header__link-to--investor"
                onClick={clickInvestorHandler}
            >
                {dfa?.emitter_full_name ?? dfa?.emitter_info?.short_name}
            </a>
            <div className={styles.badge}>
                <h4>Выплата купонного дохода</h4>
            </div>
        </div>
    )
}
