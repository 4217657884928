import { ICouponPaymentCalendarTable } from '@dltru/dfa-models'
import { ColumnsType, EMPTY_FIELD_VALUE, getRublesFromPennies } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

export const getGridConfig = (): ColumnsType<ICouponPaymentCalendarTable> => {
    const columns: ColumnsType<ICouponPaymentCalendarTable> = [
        {
            title: 'Плановая дата выплаты',
            dataIndex: 'payment_date',
            key: 'payment_date',
            width: 170,
            sorter: false,
            ellipsis: false,
            render: (value: string, record: ICouponPaymentCalendarTable) => {
                const obj = {
                    children: value,
                    props: { rowSpan: record.rowSpan },
                }

                return obj
            },
        },
        {
            title: 'Фактическая дата выплаты',
            dataIndex: 'cpf_payment_date',
            key: 'cpf_payment_date',
            align: 'right',
            width: 170,
            sorter: true,
            render: (value: string) => {
                if (value) {
                    return dayjs(value).format('DD.MM.YYYY hh:mm')
                }

                return EMPTY_FIELD_VALUE
            },
        },
        {
            title: 'Размер выплаты на 1 ЦФА, ₽',
            dataIndex: 'cpf_price_per_dfa',
            key: 'cpf_price_per_dfa',
            align: 'right',
            width: 170,
            sorter: true,
            render: (value: number) => {
                return value ? getRublesFromPennies(value) : EMPTY_FIELD_VALUE
            },
        },
        {
            title: 'Количество ЦФА, ед.',
            dataIndex: 'cpf_amount_dfa',
            key: 'cpf_amount_dfa',
            align: 'right',
            width: 170,
            sorter: true,
        },
        {
            title: 'Общая сумма выплаты, ₽',
            dataIndex: 'cpf_total_price',
            key: 'cpf_total_price',
            align: 'right',
            width: 170,
            sorter: true,
            render: (value: number) => {
                return value ? getRublesFromPennies(value) : EMPTY_FIELD_VALUE
            },
        },
    ]

    return columns
}
