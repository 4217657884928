import { CouponSettlementTypeTranslate, ICouponPaymentPayload, IDfaFront, stringToNumber } from '@dltru/dfa-models'
import { BodyCheck, BodyTitle, Box, Button, ColorModal, Space } from '@dltru/dfa-ui'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { AppState } from '@store/index'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { AccountLimitBlock } from './AccountLimitBlock'
import { Header } from './Header'

interface ICouponPaymentConfirmationProps {
    dfa: IDfaFront
    onClose: () => void
    onConfirm: () => void
    values: ICouponPaymentPayload
}

export const CouponPaymentConfirmation: FC<ICouponPaymentConfirmationProps> = ({
    dfa,
    values,
    onClose,
    onConfirm,
}) => {
    const dateOption = useSelector((store: AppState) =>
        dfaDetailsSelector.selectCouponPaymentsDatesOptionById(
            store,
            values.coupon_payment_calendar_id,
        ),
    )

    return (
        <ColorModal
            style={{ left: 240 }}
            isModalVisible={true}
            headerColor={'buying'}
            centered
            closable={true}
            header={<Header dfa={dfa} onClose={onClose} />}
            onCancel={onClose}
        >
            <div style={{ background: '#ffffff' }}>
                <BodyTitle title="Выплата купонного дохода" />
                <BodyCheck
                    label="Плановая дата выплат, к которой будет привязан платёж"
                    text={dateOption ? `${dateOption.label}` : ''}
                    labelRight
                />
                <BodyCheck
                    label="Размер выплаты на 1 ЦФА, руб."
                    price={values.price_per_dfa}
                    labelRight
                />
                <BodyCheck
                    label="Количество, ед."
                    price={dfa.min_supply_invested}
                    currency="unit"
                    labelRight
                />
                <BodyCheck
                    label="Общая сумма выплаты купонного дохода"
                    price={stringToNumber(values.price_per_dfa.toString()) * (dfa.min_supply_invested ?? 0)}
                    labelRight
                />
                <BodyCheck
                    label="Способ расчётов"
                    text={CouponSettlementTypeTranslate[dfa.coupon_settlement_type]}
                    labelRight
                />

                <Box padding={[0, 0, 16, 0]}>
                    <AccountLimitBlock />
                </Box>

                <Box padding={[24, 0, 0, 0]} align="end">
                    <Space size={16}>
                        <Button size="large" borderRadius={16} onClick={onClose}>
                            Отмена
                        </Button>
                        <Button size="large" type="primary" borderRadius={16} onClick={onConfirm}>
                            Подтвердить выплату
                        </Button>
                    </Space>
                </Box>
            </div>
        </ColorModal>
    )
}
