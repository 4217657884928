import { DfaStatusEnum, IDfaFront } from '@dltru/dfa-models'
import { Button, MoneyOut, Table } from '@dltru/dfa-ui'
import { FC, useCallback, useState } from 'react'
import { getGridConfig } from './gridConfig'
import { CouponPaymentModal } from '../CouponPaymentModal'

import styles from './CouponPaymentTable.m.less'
import { Empty } from '@dltru/dfa-ui'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSlice } from '@store/dfa/details'

interface ICouponPaymentTableProps {
    dfa: IDfaFront
}

export const CouponPaymentTable: FC<ICouponPaymentTableProps> = ({ dfa }) => {
    const dispatch = useDispatch()
    const { data, total } = useSelector(dfaDetailsSelector.selectCouponPayments)
    const isPaymentsEmpty = useSelector(dfaDetailsSelector.selectIsCouponPaymentsEmpty)
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isShowModal, setIsShowModal] = useState(false)

    const status = useSelector(dfaDetailsSelector.selectDfaDetailsStatus)
    const currentUser = useSelector(authSelector.selectUserUid)
    const isEmitter = currentUser === dfaDetails.emitter_id
    const isShowButton = status === DfaStatusEnum.emitment_success && isEmitter

    const onPageChange = useCallback((page: number) => {
        dispatch(dfaDetailsSlice.actions.setCouponeCurrentPage(page))
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    <h4 className={styles.title}>График выплат</h4>
                </div>
                <div className={styles.rightHeader}>
                    <h4 className={styles.title}>История выплат купонного дохода</h4>
                </div>
            </div>
            <div className={styles.body}>
                <Table
                    className={styles.table}
                    columns={getGridConfig()}
                    dataSource={data}
                    scroll={{ y: 630 }}
                    rowKey="uuid"
                    pagination={{
                        onChange: onPageChange,
                        total: total,
                        showSizeChanger: false,
                    }}
                />
                {isPaymentsEmpty && (
                    <div className={styles.empty}>
                        <Empty description="Эмитент еще не производил выплаты" />
                    </div>
                )}
            </div>
            {isShowButton && (
                <div className={styles.footer}>
                    <Button
                        borderRadius={16}
                        onClick={() => setIsShowModal(true)}
                        type="primary"
                        icon={<MoneyOut />}
                    >
                        Выплатить купонный доход
                    </Button>
                </div>
            )}
            {isShowModal && <CouponPaymentModal dfa={dfa} onClose={() => setIsShowModal(false)} />}
        </div>
    )
}
