import { ICouponPaymentPayload, IDfaFront, getPennies } from '@dltru/dfa-models'
import { ColorModal } from '@dltru/dfa-ui'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { FC, useCallback, useState } from 'react'
import { EActiveBodyType } from './types'
import { AccountRefillInfo } from './AccountRefillInfo'
import { CouponPaymentForm } from './CouponPaymentForm'
import { Header } from './Header'
import { CouponPaymentConfirmation } from './CouponPaymentConfirmation'
import { useDispatch } from 'react-redux'
import { setCouponPayment } from '@store/dfa/details'

interface ICouponPaymentModalProps {
    onClose: () => void
    dfa?: IDfaFront
}

export const CouponPaymentModal: FC<ICouponPaymentModalProps> = ({ dfa, onClose }) => {
    const dispatch = useDispatch()
    const [type, setType] = useState<EActiveBodyType>(EActiveBodyType.Payment)
    const [isShowConfirmation, setIsShowConfirmation] = useState(false)
    const [values, setValues] = useState<ICouponPaymentPayload>()

    const changeTypeHandler = useCallback((value: EActiveBodyType) => {
        setType(value)
    }, [])

    const onFinishHandler = useCallback((value: ICouponPaymentPayload) => {
        setValues(value)
        setIsShowConfirmation(true)
    }, [])

    const onConfirmationHandler = useCallback(() => {
        if (values && dfa && dfa.id) {
            dispatch(
                setCouponPayment({
                    asset_id: dfa.id,
                    coupon_payment_calendar_id: values.coupon_payment_calendar_id,
                    price_per_dfa: getPennies(values.price_per_dfa),
                }),
            )
            onClose()
            onCloseConfirmationHandler()
        }
    }, [values, dfa])

    const onCloseConfirmationHandler = useCallback(() => {
        setIsShowConfirmation(false)
        // onClose()
    }, [])

    return (
        <>
            <ColorModal
                isModalVisible={true}
                headerColor={'buying'}
                centered
                closable={true}
                onCancel={onClose}
                header={<Header dfa={dfa} onClose={onClose} />}
            >
                <TransitionGroup className="slide-animation__block">
                    {type === EActiveBodyType.Payment && dfa && (
                        <CSSTransition timeout={300} classNames="slide-animation">
                            <CouponPaymentForm
                                onChangeType={changeTypeHandler}
                                onFinish={onFinishHandler}
                                dfa={dfa}
                                onClose={onClose}
                            />
                        </CSSTransition>
                    )}
                    {type === EActiveBodyType.Refill && (
                        <CSSTransition timeout={300} classNames="slide-animation">
                            <AccountRefillInfo onClose={changeTypeHandler} />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </ColorModal>

            {isShowConfirmation && dfa && values && (
                <CouponPaymentConfirmation
                    onClose={onCloseConfirmationHandler}
                    onConfirm={onConfirmationHandler}
                    values={values}
                    dfa={dfa}
                />
            )}
        </>
    )
}
