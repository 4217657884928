import {
    CouponSettlementTypeTranslate,
    ECouponPaymentMethod,
    ICouponPaymentPayload,
    IDfaFront,
    stringToNumber,
} from '@dltru/dfa-models'
import { FieldData } from 'rc-field-form/lib/interface'
import {
    BodyCheck,
    BodyInputBlock,
    Box,
    commonValidators,
    CurrencyInput,
    Form,
    TradeButton,
    TwoColumns,
    WrappedSelect,
} from '@dltru/dfa-ui'
import { AccountLimitBlock } from '@pages/OwnershipTransfer/CreateDFAOwnershipTransferRequestPage/components/AccountLimitBlock'
import { FC, useCallback, useEffect, useState } from 'react'
import { EActiveBodyType } from './types'
import { useSelector } from 'react-redux'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import styles from './CouponPaymentModal.m.less'

interface ICouponPaymentFormProps {
    onFinish: (values: ICouponPaymentPayload) => void
    onChangeType: (value: EActiveBodyType) => void
    onClose: () => void
    dfa: IDfaFront
}

const title = (
    <>
        Размер выплаты
        <br />
        на 1 ЦФА, руб.
    </>
)

export const CouponPaymentForm: FC<ICouponPaymentFormProps> = ({ onFinish, onChangeType, dfa }) => {
    const [form] = Form.useForm()
    const datesOptions = useSelector(dfaDetailsSelector.selectCouponPaymentsDatesOptions)

    useEffect(() => {
      if (dfa.coupon_payment_method_type === ECouponPaymentMethod.FIXED && dfa.coupon_payment_amount) {
        form.setFieldsValue({ price_per_dfa: dfa.coupon_payment_amount })
      }
    }, [])

    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [total, setTotal] = useState(0)

    const onChangeTypeHandler = useCallback(() => {
        onChangeType(EActiveBodyType.Refill)
    }, [])

    const onFieldsChange = useCallback((fields: FieldData[]) => {
        const priceChanges = fields.find((item) =>
            (item.name as string[]).includes('price_per_dfa'),
        )
        if (priceChanges && dfa.min_supply_invested) {
            const totalSum = stringToNumber(priceChanges.value) * dfa.min_supply_invested
            setTotal(totalSum)

            if (stringToNumber(priceChanges.value) === 0) {
                setIsButtonDisabled(true)
            } else {
                setIsButtonDisabled(false)
            }
        }
    }, [])

    const onFinishHandler = useCallback(
        (value) => {
            onFinish({ ...value, total })
        },
        [total],
    )

    return (
        <div style={{ background: '#ffffff' }}>
            <Form
                form={form}
                name="tradeModal"
                onFinish={onFinishHandler}
                onFieldsChange={onFieldsChange}
            >
                <TwoColumns gap="0 32px">
                    <BodyInputBlock title={title}>
                        <CurrencyInput
                            name="price_per_dfa"
                            rules={[
                                {
                                    validator: commonValidators.positive(),
                                },
                            ]}
                            required
                        />
                    </BodyInputBlock>
                    <BodyInputBlock
                        title="Плановая дата выплат, к которой будет привязан платёж"
                        //explain={!hasIsAmountFieldError && `Доступно ${restAmount}`}
                    >
                        <WrappedSelect
                            size="middle"
                            className={styles.input}
                            name="coupon_payment_calendar_id"
                            options={datesOptions}
                            required
                        />
                    </BodyInputBlock>
                </TwoColumns>

                <Box padding={[8, 0, 0, 0]}>
                    <BodyCheck
                        label="Количество ЦФА, ед."
                        price={dfa.min_supply_invested}
                        labelRight
                        currency="unit"
                    />
                </Box>

                <Box padding={[8, 0, 0, 0]}>
                    <BodyCheck
                        label={'Общая сумма выплаты купонного дохода'}
                        price={total}
                        labelRight
                    />
                </Box>

                <Box padding={[8, 0, 0, 0]}>
                    <BodyCheck
                        label="Способ расчётов"
                        text={CouponSettlementTypeTranslate[dfa.coupon_settlement_type]}
                        labelRight
                    />
                </Box>

                <Box padding={[0, 0, 24, 0]}>
                    <AccountLimitBlock actionAccount={onChangeTypeHandler} />
                </Box>

                <Box padding={[24, 0, 0, 0]} align="center">
                    <TradeButton
                        color={'green'}
                        onClick={form.submit}
                        disabled={isButtonDisabled}
                        label={'Выплатить купонный доход'}
                    />
                </Box>
            </Form>
        </div>
    )
}
