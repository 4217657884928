// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badge_dNTB2 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 16px 0;\n  box-shadow: inset 0px -5px 0px #73d13d;\n}\n.badge_dNTB2 h4 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  text-transform: uppercase;\n  margin: 0;\n  color: #73d13d;\n}\n.input_qbfrP .ant-input {\n  height: 40px;\n}\n.input_qbfrP .ant-form-item-explain {\n  top: calc(100% - 10px) !important;\n  transition: none;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEmission/DFAEmissionCardPage/components/CouponPaymentModal/CouponPaymentModal.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,sCAAA;AADJ;AAJA;EAQQ,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,SAAA;EACA,cAAA;AADR;AAKA;EAGY,YAAA;AALZ;AAEA;EASY,iCAAA;EACA,gBAAA;AARZ","sourcesContent":["@import '/src/assets/style/palette';\n\n.badge {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 16px 0;\n    box-shadow: inset 0px -5px 0px @green-5;\n\n    h4 {\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 24px;\n        text-transform: uppercase;\n        margin: 0;\n        color: @green-5;\n    }\n}\n\n.input {\n    :global {\n        .ant-input {\n            height: 40px;\n        }\n    }\n\n    :global {\n        .ant-form-item-explain {\n            top: calc(100% - 10px) !important;\n            transition: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "badge_dNTB2",
	"input": "input_qbfrP"
};
export default ___CSS_LOADER_EXPORT___;
